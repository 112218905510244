import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import API from '@/api/manager/exp/expDetail';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "expDetail",
    components: {
        VueUeditorWrap
    },
    data() {
        return{
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "70%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            rules: {
                id: [
                    { required: true, message: '请输入id', trigger: 'blur' },
                
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                lab_id: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ],
                des: [
                { required: true, message: '请输入描述', trigger: 'blur' },
                ],
                cost: [
                { required: true, message: '请输入价格', trigger: 'blur' },
                ],
                CID: [
                { required: true, message: '请输入关卡数', trigger: 'blur' },
                ],
                status: [
                { required: true, message: '请选择状态', trigger: 'blur' },
                ],
                sign: [
                { required: true, message: '请选择标识', trigger: 'blur' },
                ],
            },
            adminList:[],
            developList:[
                {
                    id:0,
                    name:'开发中'
                },
                {
                    id:1,
                    name:'开发完成'
                }
            ],
            // 素材列表
            articleList:[],
            // 虚拟试验分类页码
            labPage:0,
            labPageSize:10,
            AddFormData:{
                id:'',
                article_id:'',
                name:'',
                des:'',
                // helpurl:'',
                lab_id:'',
                cost:'',
                outday:0,
                CID:'',
                status:'',
                pic:[],
                logo:'',
                sign:'',
                basics_total:'',
                basics_browse:'',
                create_uid:'',
                school_id:[],
                is_develop:'0',
                step:'',
                parameter:[],
                parameter_table:[],
                moolsnet_admin_id:'',
                helpurl:'',
                project_id:'',
            },
            // 所属学校id
            school_id:[],
            // 所属开发者id
            create_uid:[],
            tableRow:0,
            tableColumn:0,
            showTable:false,
            // 学校列表
            schoolList:[],
            labList:[],
            userList:[],
            outdayList:[
                {
                    id:0,
                    name:'永久'
                }
            ],
            // 状态列表
            statusList:[
                {
                    id:0,
                    name:'未开放'
                },
                {
                    id:1,
                    name:'已开放'
                }
            ],
            // sign列表
            signList:[
                {
                    id:0,
                    name:'APP'
                },
                {
                    id:1,
                    name:'小游戏'
                },
                {
                    id:2,
                    name:'PC'
                },
                {
                    id:3,
                    name:'VR'
                },
                {
                    id:4,
                    name:'web'
                },
            ],
            // 上传图片接口地址
            uploadImagePic: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminExp/uploadImagePic",
            // 预览图片url
            dialogImageUrl: '',
            dialogVisible: false,
            labloading:true,
            adminloading:true,
            schoolloading:true,
            articleLoading:true,
            useloading:true,
            // 轮播图列表
            fileList:[],
        }
    },
    created(){
        let self = this;
        self.getschoolList();
        self.getlabList();
        if(self.$route.query.id)
        {
            self.getData();
        }
        
    },
    methods:
    {
        ready(editorInstance) {
            var self = this;
            editorInstance.focus();
            var str = "exam_paper_" + self.bank_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        changeDevelop(row)
        {
            let self = this;
            // console.log(self.AddFormData.is_develop);
        },
        setTable()
        {
            this.AddFormData.parameter_table=[];
            for(let i = 0;i < this.tableRow;i++)
            {
                var array = {};
                for(var j = 0;j < this.tableColumn; j++)
                {
                    let a = "id" + (j +1)
                    array[a] = ""
                    // array.value = '';
                    
                }
                this.AddFormData.parameter_table.push(array);
                
            }
            if(!this.tableRow || !this.tableColumn)
            {
                this.showTable = false;
                this.$Tips({
                    // 消息提示内容
                    message: '请填写表格行列数',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            else
            {
                this.showTable = true;
            }
        
        },
        getData()
        {
            let self = this;
            // 请求参数
            let data = {
                id: self.$route.query.id,
            };
            nprogressStart();
            self.$Loading.show();
            this.AddFormData.pic = [];
            this.fileList = []
            // 获取虚拟实验分类请求
            API.getExpInfoById(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.useloading = false;
                        let lab = self.labList?.find((item)=>{
                            return item.id == result.data.lab_id
                        })
                        if(!lab)
                        {
                            self.labList.push({
                                id: result.data.lab_id,
                                name:result.data.labName
                            })
                        }
                        self.userList = [];
                        if(result.data.userList)
                        {
                            result.data.userList.forEach((item)=>{
                                self.userList.push({
                                    name:item.name,
                                    userid:item.userid + ''
                                })
                            })
                        }
                        // self.userList.push(result.data.userList);
                        self.create_uid = [];
                        if(result.data.create_uid)
                        {
                            self.create_uid = result.data.create_uid.split(',');
                        }
                        self.articleList.push({
                            id:result.data.article_id,
                            title:result.data.articleName
                        })  
                        self.schoolList = [];
                        self.school_id = '';
                        if(result.data.schoolList)
                        {
                            result.data.schoolList.forEach((item)=>{
                                self.schoolList.push({
                                    name:item.name,
                                    id:item.id + ''
                                })
                            })
                            self.school_id = result.data.school_id.split(',');
                        }
                        // self.schoolList.push(result.data.schoolList);
                        self.adminList.push({
                            userid:result.data.moolsnet_admin_id,
                            name:result.data.moolsnetAdminName
                        }) 
                        try {
                            let table = JSON.parse(result.data.parameter_table);
                            result.data.parameter_table = JSON.parse(table);
                        } catch (error) {
                            
                        }
                        let pic = ''
                        try {
                            pic = JSON.parse(result.data.pic);
                        } catch (error) {
                            
                        }
                        if(pic)
                        {
                            pic?.forEach((item)=>{
                                self.fileList.push({
                                    name:'',
                                    url:item
                                })
                            })
                        }
                        for (const key in self.AddFormData) 
                        {
                            self.AddFormData[key] = result.data[key]
                        }
                        if( self.AddFormData.parameter_table) 
                        {
                            self.showTable = true
                            self.tableRow = self.AddFormData.parameter_table.length
                            let length = Object.keys(self.AddFormData.parameter_table[0])
                            self.tableColumn = length.length
                        }
                        self.AddFormData.is_develop = self.AddFormData.is_develop == '0' ? true : false;
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        
        getschoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    self.schoolloading = false;
                    if(result.code > 0 )
                    {
                        self.schoolList = result.list
                    }
                    // else
                    // {
                    //     Message.error('系统错误')
                    // }
                })                
        },
        getArticleList(e) {
            try {
                let self = this;
                let data = {
                    search: e
                }
                if (e) {
                    API.getArticleList(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.articleList = result.articleList;
                                self.articleLoading = false
                            }
                            else {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },
        editArticle()
        {
            // window.open(`#/teacher/article/articleUpdate?article_id=${this.articleList[0].id}&name=${this.articleList[0].title}&hideMenu=1&type=0`)
            window.open(`#/teacher/article/articleUpdate?article_id=${this.AddFormData.article_id}&hideMenu=1&type=0`)
        },
        previewArticle()
        {
            //  window.open(`#/teacher/article/articlePreview?article_id=${this.articleList[0].id}&name=${this.articleList[0].title}&hideMenu=1&type=0`)
            // window.open(`#/teacher/article/articlePreview?article_id=${this.AddFormData.article_id}&hideMenu=1&type=0`)
            window.open('https://app.mools.net/lims/article/index.html?article_id='+this.AddFormData.article_id);
        },
        handleRemove(file, fileList) {
            // console.log(fileList);
            this.fileList = fileList
        },
        handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
        },
        handleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.fileList.push(
                    {
                        name:'',
                        url:res.data
                    }
                );
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },

        handlelogoSuccess(res)
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.logo = res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },

        save()
        {
            let self = this;
            if(!self.AddFormData.id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入虚拟实验id',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.name)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入虚拟实验名称',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.lab_id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择虚拟实验分类',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.des)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入虚拟实验描述',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.cost === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入虚拟实验价格',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.CID === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入虚拟实验关卡总数',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.status === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择虚拟实验状态',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.sign === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择虚拟实验sign',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            self.AddFormData.pic= self.fileList.map((item)=>{
                return item.url
            })??[]
            self.AddFormData.is_develop = self.AddFormData.is_develop ? 0 : 1;
            self.AddFormData.parameter = self.AddFormData.parameter_table;
            // 学校重新赋值
            self.AddFormData.school_id = self.school_id.toString();
            // 开发者重新赋值
            self.AddFormData.create_uid = self.create_uid.toString();
            let data = {
                ...self.AddFormData,
                symbol:self.$route.query.id ? 'update' : 'add'
            };
            // 添加/修改虚拟实验
            API.updateExp(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.$router.push('/manager/expDetail?id=' + self.AddFormData.id);
                        self.getData();
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        getlabList(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e??'',
                page:self.labPage,
                pagesize:self.labPageSize
            };
            // 获取虚拟实验分类请求
            API.getExpLabList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.labloading = false
                        self.labList.push(...result.logList);
                        // console.log(result.logList.flat());
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        searchLab(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e??'',
                page:1,
                pagesize:self.labPageSize
            };
            // 获取虚拟实验分类请求
            API.getExpLabList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.labloading = false;
                        self.labList=result.logList;
                        // console.log(result.logList.flat());
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        getuserList(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e,
            };
            // 获取虚拟实验分类请求
            API.getDevelopByName(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.useloading = false
                        self.userList = result.data
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        getadminList(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e,
            };
            // 获取虚拟实验分类请求
            API.getAdminByName(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.adminList = result.data
                        self.adminloading = false
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        }
    }
}