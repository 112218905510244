<template>
    <div class="expDetail">
      <el-card>
        <div class="page">
          <el-form :rules="rules" :model="AddFormData" label-width="150px" label-position="left">
            <el-form-item label="虚拟实验ID" prop="id">
              <el-input v-model="AddFormData.id"></el-input>
            </el-form-item>
            <el-form-item label="虚拟实验名称" prop="name">
              <el-input v-model="AddFormData.name"></el-input>
            </el-form-item>
            <el-form-item label="分类名称" prop="lab_id">
              <el-select
                  v-model="AddFormData.lab_id"
                  filterable
                  remote
                  clearable
                  :loading="labloading"
                  placeholder="请搜索选择分类"
                  :remote-method="searchLab">
                  <el-option
                      v-for="item in labList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述" prop="des">
              <vue-ueditor-wrap
                v-model="AddFormData.des"
                :config="myConfig"
                @ready="ready"
            ></vue-ueditor-wrap>
            </el-form-item>
            <el-form-item label="价格" prop="cost">
              <el-input v-model="AddFormData.cost"></el-input>
            </el-form-item>
            <el-form-item label="关卡总数" prop="CID">
              <el-input v-model="AddFormData.CID"></el-input>
            </el-form-item>
            <el-form-item label="sign" prop="sign">
              <el-select
                  v-model="AddFormData.sign"
                  placeholder="请选择">
                  <el-option
                      v-for="item in signList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态(是否开放购买)" prop="status">
              <el-select
                  v-model="AddFormData.status"
                  placeholder="请选择状态">
                  <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="过期时间">
              <el-select
                  disabled
                  v-model="AddFormData.outday"
                  placeholder="请选择过期时间">
                  <el-option
                      v-for="item in outdayList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select>
              <!-- <el-input disabled v-model="AddFormData.outday"></el-input> -->
            </el-form-item>
            <el-form-item label="学校" >
              <el-select
                    class="width800"
                    v-model="school_id"
                    filterable
                    remote
                    placeholder="请选择学校，可多选！"
                    multiple
                    clearable
                    :reserve-keyword="true"
                    :remote-method="getschoolList"
                    >
                    <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
              </el-select>
              <!-- <el-select
                  v-model="AddFormData.school_id"
                  filterable
                  remote
                  clearable
                  :loading="schoolloading"
                  placeholder="请搜索选择学校"
                  :remote-method="getschoolList">
                  <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="帮助素材">
              <!-- <el-input v-model="AddFormData.helpurl"></el-input> -->
              <el-select
                  class="articleHelp"
                  v-model="AddFormData.article_id"
                  filterable
                  remote
                  clearable
                  :loading="articleLoading"
                  placeholder="请搜索选择素材！"
                  :remote-method="getArticleList">
                  <el-option
                      v-for="item in articleList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                  </el-option>
              </el-select>
              <el-button :disabled="!AddFormData.article_id" class="themecancelButton" @click="previewArticle">预览</el-button>
              <el-button :disabled="!AddFormData.article_id" class="themeButton" @click="editArticle">编辑</el-button>
            </el-form-item>
            <el-form-item label="轮播图">
              <el-upload
                :file-list="fileList"
                :action="uploadImagePic"
                :on-success="handleAvatarSuccess"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="外部显示图">
              <el-upload
                :action="uploadImagePic"
                :show-file-list="false"
                :on-success="handlelogoSuccess">
                <img v-if="AddFormData.logo" :src="AddFormData.logo" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="通过记录总数基数">
              <el-input v-model="AddFormData.basics_total"></el-input>
            </el-form-item>
            <el-form-item label="统计浏览记录基数">
              <el-input v-model="AddFormData.basics_browse"></el-input>
            </el-form-item>
            <el-form-item label="开发者">
              <el-select
                    class="width800"
                    v-model="create_uid"
                    filterable
                    remote
                    placeholder="请搜索选择开发者，可多选！"
                    multiple
                    clearable
                    :reserve-keyword="true"
                    :remote-method="getuserList"
                    >
                    <el-option
                      v-for="item in userList"
                      :key="item.userid"
                      :label="item.name"
                      :value="item.userid"
                    >
                    </el-option>
              </el-select>
              <!-- <el-select
                  v-model="AddFormData.create_uid"
                  filterable
                  remote
                  clearable
                  :loading="useloading"
                  placeholder="请搜索选择开发者"
                  :remote-method="getuserList">
                  <el-option
                      v-for="item in userList"
                      :key="item.userid"
                      :label="item.name"
                      :value="item.userid">
                  </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="是否开发中">
              <template slot-scope="scope">
                <el-switch
                  @change = "changeDevelop(scope.row)"
                  v-model="AddFormData.is_develop"
                  active-color="#0D5570"
                  inactive-color="#CCCCCC">
                </el-switch>
              </template>
            </el-form-item>
            <el-form-item label="步骤（step）">
              <el-input
                type="textarea"
                autosize
                placeholder="请输入步骤"
                v-model="AddFormData.step">
              </el-input>
            </el-form-item>
            <el-form-item label="参数设置">
              <div class="setTable">
              <el-input
                  v-model="tableRow"
                  placeholder="请输入行数"
                  type="number"
              ></el-input>
              <el-input
                  v-model="tableColumn"
                  placeholder="请输入列数"
                  type="number"
              ></el-input>
              <el-button class="themeButton" @click="setTable" >设置表格</el-button>
              <div   class="warning-div">请不要随意更改行列，否则数据会丢失，需要重新填写 ！</div>
              <div  v-show="showTable" class="inout_virtual">#代表正常输入值，留空代表不可编辑</div>
              <table class="tableGrid" v-show="showTable" style="width:80%">
                  <tr  v-for="(item,index) in AddFormData.parameter_table" :key="index" style="width:100%">
                      <td v-for="(value,it) in item" :key="it" >
                          <el-input v-model="AddFormData.parameter_table[index][it]" placeholder="请输入内容" ></el-input>
                      </td>
                  </tr>
              </table>
            </div>
            </el-form-item>
            <el-form-item label="内部负责人">
              <el-select
                  v-model="AddFormData.moolsnet_admin_id"
                  filterable
                  :loading="adminloading"
                  remote
                  clearable
                  placeholder="请搜索选择内部负责人"
                  :remote-method="getadminList">
                  <el-option
                      v-for="item in adminList"
                      :key="item.userid"
                      :label="item.name"
                      :value="item.userid">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="app视频素材地址">
              <el-input
                type="text"
                autosize
                placeholder="请输入视频地址"
                v-model="AddFormData.helpurl">
              </el-input>
            </el-form-item>
            <el-form-item label="关联项目id">
              <el-input
                type="text"
                autosize
                placeholder="请输入项目id"
                v-model="AddFormData.project_id">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
      <div class="save">
        <!-- <el-button type="primary" @click="Back">返回</el-button> -->
        <el-button class="themeButton" @click="save">保存</el-button>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </template>
<style lang="scss">
  // .expDetail .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  //   width: 100%;
  //   height: 13.5vw;
  // }
  .expDetail .el-upload-list--picture-card .el-upload-list__item{
    width: 30%;
    height: 15vw;
    overflow: hidden;
  }
  .expDetail .el-upload-list--picture-card .el-upload-list__item img{
    width: 100%;
    // height: 100%;
  }
  .width800{
    width:800rpx;
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/css/manager/exp/expDetail.scss";
</style>
<script>
  import expDetail from "@/assets/js/manager/exp/expDetail.js";
  import { TableColumn } from "element-ui";
  export default {
    ...expDetail,
  };
</script>